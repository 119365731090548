<template>
  <header>
    <a-spin :spinning="state.spinning" size="large" tip="Loading...">
    <section class="card detail" v-if="state.dataInfo">
      <div class="cover">
        <img :src="state.dataInfo.coverImg" alt="">
      </div>
      <div class="detail-info">
        <div class="name">
          {{ state.dataInfo.courseName }}
        </div>
        <div class="period" v-if="state.dataInfo.limitStartTime || state.dataInfo.limitEndTime">
          {{ $t('subject.learning_time') }}:
          <span v-if="state.dataInfo.limitStartTime">
            {{ dateFormat(state.dataInfo.limitStartTime, 'YYYY-MM-DD') }} 00:00
          </span>
          <span v-else>{{ $t('Unrestricted') }}</span>
          <span v-if="state.dataInfo.limitEndTime">
            ~
            {{ dateFormat(state.dataInfo.limitEndTime, 'YYYY-MM-DD') }} 23:59
          </span>
          <span v-else>{{ $t('Unrestricted') }}</span>
          <template v-if="state.dataInfo.studyCondition">
            (
            <span v-if="[1, 3].includes(state.dataInfo.studyCondition)">
              {{ $t('YouCanLearnBeforeYouStart') }}
            </span> 
            <span v-if="state.dataInfo.studyCondition === 3">、</span>
            <span v-if="[2, 3].includes(state.dataInfo.studyCondition)">
              {{ $t('YouCanLearnAtTheEnd') }}
            </span> 
            )
          </template>
        </div>
        <div class="period" v-if="state.dataInfo.signUpSetting && state.dataInfo.signUpSetting.startTime">
          {{ $t('EnrollmentTime') }}:
          <span>{{ dateFormat(state.dataInfo.signUpSetting.startTime) }}</span>
          <span>~</span>
          <span>{{ dateFormat(state.dataInfo.signUpSetting.endTime) }}</span>
        </div>
        <div class="lecture">{{ $t("XB_Course_Lecturer") }}：{{ computedLecture(state.dataInfo.lecturers) }}</div>
        <div class="static">
          <div class="score">
            <span v-if="state.questionnaire">
              {{
                state.dataInfo.questionnaireTotalUser > 0
                  ? (
                      state.dataInfo.questionnaireTotalScore /
                      state.dataInfo.questionnaireTotalUser
                    ).toFixed(1)
                  : "0.0"
              }}
            </span>
            <span v-else>
              {{
                state.dataInfo.starUsers > 0
                  ? (state.dataInfo.starScore / state.dataInfo.starUsers).toFixed(1)
                  : "0.0"
              }}
            </span>
            {{ $t("CM_Points") }}
          </div>
          <div class="other">
            <div class="other-item">
              <template v-if="state.dataInfo.isLike">
                <LikeFilled class="active" />
              </template>
              <template v-else>
                <LikeOutlined />
              </template>
              {{ state.dataInfo.likes }}
            </div>
            <div class="other-item">
              <template v-if="state.dataInfo.myStar">
                <StarFilled class="active" />
              </template>
              <template v-else>
                <StarOutlined />
              </template>
              {{ state.questionnaire ? state.dataInfo.questionnaireTotalUser : state.dataInfo.starUsers }}
            </div>
          </div>
        </div>
        <div class="action">
          <div class="action-item">
            <!-- 进入学习 -->
            <a-button type="primary" size="large" block v-if="[1, 5].includes(state.status)" @click="jump">
              {{ $t("LB_Course_ToStudy") }}
            </a-button>
            <!-- 加入学习 -->
            <a-button type="primary" size="large" block v-else-if="state.status === 2" @click="jump">
              {{ $t("XB_Join_Study") }}
            </a-button>
            <!-- 报名学习 -->
            <a-button type="primary" size="large" block v-else-if="state.status === 3" @click="signup">
              {{ $t("Enrollment") }}
            </a-button>
            <!-- 审核中 -->
            <a-button type="primary" disabled size="large" block v-else-if="state.status === 4">
              {{ $t("teacher.under_review") }}
            </a-button>
          </div>
          <div class="action-item" v-if="[4, 5].includes(state.status)">
            <!-- 取消报名 -->
            <a-button type="primary" size="large" block @click="cancelSignup">
              {{ $t("Lab_Signup_T_CancelRegistration") }}
            </a-button>
          </div>
        </div>
      </div>
    </section>
    </a-spin>
  </header>
  <main class="container">
    <section class="card catalog">
      <a-tabs>
        <a-tab-pane key="1" :tab="$t('course.course_catalog')">
          <div class="section">
            <div class="section-item" v-for="(item, index) in state.section" :key="index">
              <div class="sort">
                {{ $t("course.section", [index + 1]) }}
              </div>
              <div class="type">
                <div class="type-icon">
                  <PlaySquareOutlined />
                </div>
                <div class="type-name">{{ getFileType(item.fileType) }}</div>
              </div>
              <div class="name">{{ item.fileName }}</div>
              <div class="duration">{{ $t("XB_Duration") }}: {{ formatSeconds(item.times) || '--' }}</div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('course.course_intro')">
          <div v-if="state.dataInfo.intro">{{ state.dataInfo.intro }}</div>
          <a-empty
            v-else
            :image="require('@/assets/image/no_data_3.png')"
            :image-style="{ height: '186px' }"
            style="padding: 60px 0"
          >
            <template #description>
              <span style="color: #999">{{ $t("LB_NoIntroduction") }}</span>
            </template>
          </a-empty>
        </a-tab-pane>
      </a-tabs>
    </section>
    <section class="recommend">
      <a-card :title="$t('Pub_RecommendedCourse')">
          <template #extra>
            <a href="javascript:;" class="refresh" @click="brushList()">
              <SyncOutlined :style="{ fontSize: '12px' }" />
              {{ $t("cm_refresh") }}
              <!-- 刷一刷 -->
            </a>
          </template>
          <template v-if="state.recommendList.length > 0">
            <a
              :href="`/course/center?id=${item.courseId}}${
                newWindow == 1 ? '&ddtab=true' : ''
              }`"
              :target="newWindow == 1 ? '_blank' : '_self'"
              class="tjkc-li"
              v-for="(item, index) in state.recommendList.slice(0, 6)"
              :key="index"
            >
              <img :src="item.coverImg" class="coverImg" />
              <div class="info">
                <p class="title">{{ item.courseName }}</p>
                <p>
                  {{ $t("subject.learners") }}：{{ item.learnUsers
                  }}{{ $t("subject.people") }}
                </p>
                <!-- 学习人数： 人-->
                <p>
                  <!-- 讲师： -->
                  {{ $t("XB_Course_Lecturer") }}：<template
                    v-if="item.lecturers"
                    ><OpenData
                      type="userName"
                      :openid="item.lecturers[0].name" /></template
                  ><template v-else>-</template>
                </p>
              </div>
            </a>
          </template>
          <a-empty v-else :description="$t('CM_Empty')" />
          <!-- CM_Empty -->
      </a-card>
    </section>
  </main>
</template>

<script setup>
import { getCurrentInstance, onMounted, reactive, computed } from 'vue';
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { getFileType } from "@/utils/business.js";
import { getTaskDetail } from "@/api/project";
import { detail, courseList, getCourseStatus, signupCourse, cancelSignupCourse } from "@/api/course";
import { getCurrentTime } from "@/api/user";
import { dateFormat, formatTime, formatSeconds } from "@/utils/tools.js";
import { auditTmplDetailbyType } from "@/api/auditCenter";

const { t: $t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();

const state = reactive({
  spinning: true,
  courseId: parseInt(
    route.query.id ||
      (route.query.htsyautoauth ? route.query.htsyautoauth.split("*")[1] : 0)
  ),
  taskId: parseInt(route.query.taskId || 0),
  sid: parseInt(route.query.sid || 0),
  did: parseInt(route.query.did || 0),
  dataInfo: {},
  questionnaire: null,
  section: [],
  coursePath: {
    fullPath: route.fullPath.replace(/center/, 'detail'),
    path: route.path.replace(/center/, 'detail'),
    query: route.query,
  },
  recommendList: [],
  status: 1, // 课程状态 0没有学习权限/1可学习/2可加入学习/3可报名/4审核中/5报名已通过
  recordId: 0, // 报名id
});
let currentTime = 0;
async function getStatus() {
  const result = await getCourseStatus(state.courseId);
  if (result.ret === 0) {
    state.status = result.data.status;
    state.recordId = result.data.recordId;
  }
};
async function getDetail() {
  let action = detail;
  let params = { id: state.courseId };
  if (state.taskId > 0) {
    action = getTaskDetail;
    params = { id: state.taskId, did: state.did };
  }
  getCurrentTime().then(res => {
    if (res.ret === 0) currentTime = res.data || 0;
  })
  state.spinning = true;
  const res = await action(params);
  if (res.ret === 0) {
    if (state.taskId > 0) {
      state.dataInfo = res.data.course;
      state.courseId = state.dataInfo.courseId;
    } else {
      state.dataInfo = res.data;
      getStatus();
    }
    if (state.dataInfo.CoursePlusList) {
      const filter = state.dataInfo.CoursePlusList.filter(item => item.questionnaireId > 0);
      filter.length && (state.questionnaire = filter[0]);
    }
    state.section = state.dataInfo.details.map(item => {
      if (item.fileType === 3 && state.dataInfo.documentTimes ) {
        item.times = state.dataInfo.documentTimes * item.pages;
      }
      return item;
    });
  } else {
    if (res.data && res.data.learnOrder) {
      router.replace({
        path: `/project/detail`,
        query: { id: state.taskId },
      });
    } else if (res.ret == 1 && res.msg == $t("XB_Parameter_Error")) {
      // 参数错误
      router.replace({
        path: `/error`,
        query: {
          title: $t("course.course_not_exist"),
          // 抱歉，您访问的课程不存在。
          path: "/course",
          name: $t("XB_CourseCenter"),
          // 课程中心
        },
      });
    } else {
      router.replace({
        path: `/error`,
        query: {
          title: res.msg,
          // 抱歉，您访问的
          path: "/course",
          name: $t("XB_CourseCenter"),
          // // 课程中心
        },
      });
    }
  }
  state.spinning = false;
};
function computedLecture(lecturers) {
  if (!lecturers || !lecturers.length) return '--'
  const arr = lecturers.map(item => item.name);
  return arr.join('、');
}
function getRecommend() {
  let params = {
    page: 1,
    pageSize: 50,
    name: "",
    order: 2,
    status: 0,
    folderId: 0,
    types: [],
  };
  courseList(params).then((res) => {
    if (res.ret == 0 && res.data.list != null) {
      state.recommendList = shuffle(res.data.list);
    }
  });
}
function shuffle(array) {
  var m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
};
function brushList() {
  state.recommendList = shuffle(state.recommendList);
};

function hasCanStudy() {
  const { limitStartTime, limitEndTime, studyCondition } = state.dataInfo;
  let start = limitStartTime ? currentTime >= limitStartTime : true;
  let end = limitEndTime ? currentTime <= limitEndTime : true;

  if (studyCondition) {
    start = [1, 3].includes(studyCondition) ? true : start;
    end = [2, 3].includes(studyCondition) ? true : end;
  }
  let tip = '';
  tip = start ? '' : $t('NotYetDueForCourse');
  if (!tip) {
    tip = end ? '' : $t('CourseDurationHasEnded');
  }
  tip && proxy.$message.error(tip);
  return !tip;
};
function jump() {
  const isLearn = hasCanStudy();
  isLearn && router.push({
    path: state.coursePath.path,
    query: state.coursePath.query,
  });
};

const companyInfo = computed(() => store.getters.companyInfo);
async function signup() {
  const { signUpSetting } = state.dataInfo;
  let start = signUpSetting.startTime ? currentTime >= signUpSetting.startTime : true;
  let end = signUpSetting.endTime ? currentTime <= signUpSetting.endTime : true;
  if (start && end) {
    // 获取auditId;
    const result = await auditTmplDetailbyType(5);
    const res = await signupCourse({ 
      auditId: result.data.auditId,
      resourceId: state.courseId,
    });
    if (res.ret === 0) {
      proxy.$message.success($t('XB_SignUpSuccessfully'));
      state.status = res.data.status;
      state.recordId = res.data.recordId;
    } else {
      proxy.$message.error(res.msg)
    }
  } else {
    let tip = '';
    tip = start ? '' : $t('NotYetDueForEnrollment');
    if (!tip) {
      tip = end ? '' : $t('signup.signup_end');
    }
    tip && proxy.$message.error(tip);
  }
};
async function cancelSignup() {
  const res = await cancelSignupCourse({
    recordId: state.recordId,
    courseId: state.courseId,
  });
  if (res.ret === 0) {
    proxy.$message.success($t('CM_CancelRegistrationSuccessful'));
    getStatus();
  } else {
    proxy.$message.error(res.msg)
  }
}
 
onMounted(() => {
  getDetail();
  getRecommend();
});
</script>

<style scoped lang="less">
header {
  .mixinWrap();
  padding: 24px 0;
  .detail {
    display: flex;
    padding: 24px;
    overflow: hidden;
    .cover {
      width: 480px;
      height: 270px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fafafa;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 24px;
      font-size: 16px;
      color: #666;
      .name {
        font-size: 28px;
        font-weight: 600;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
      .static {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .score {
          span {
            font-size: 40px;
            font-weight: 700;
            color: #e8993e;
          }
        }
        .other {
          display: flex;
          &-item {
            padding: 0 10px;
            color: #999;
          }
          .active {
            color: var(--theme);
          }
        }
      }
      .action {
        display: flex;
        &-item {
          width: 160px;
          margin-right: 24px;
        }
      }
    }
  }
}
.container {
  .mixinWrap();
  display: flex;
  .catalog {
    flex: 1;
    padding: 24px;
    overflow: hidden;
    margin-bottom: 24px;
    .section {
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 26px 24px;
        border-radius: 4px;
        background-color: #f5f5f6;
        color: #333;
        .type {
          display: flex;
          align-items: center;
          margin: 0 24px;
          &-icon {
            margin-right: 5px;
          }
        }
        .type,
        .duration {
          color: #999;
        }
        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .duration {
          margin-left: 24px;
        }
      }
    }
  }
  .recommend {
    width: 298px;
    margin-left: 20px;
    margin-bottom: 24px;
    .refresh {
      color: #999;
    }
    .tjkc-li {
        display: block;
        position: relative;
        margin-bottom: 20px;
        .coverImg {
          position: absolute;
          width: 120px;
          height: 68px;
          border-radius: 4px;
        }
        .info {
          padding-left: 128px;
          p {
            font-size: 12px;
            color: #999999;
            margin: 0;
          }
          p.title {
            color: #333333;
            .mixinEllipsis(12px);
            margin-bottom: 20px;
          }
        }
      }
      .tjkc-li:nth-last-child(1) {
        margin-bottom: 0;
      }
  }
}
.card {
  min-height: 200px;
  border-radius: 8px;
  background: #fff;
}
</style>
